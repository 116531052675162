$(document).ready(function() {
  $('#distributors-select').select2({
    theme: 'bootstrap'
  });
  $('#countries-select').select2({
    theme: 'bootstrap'
  });
  $('#clear-countries-select').click(function (){
    $('#countries-select').val(null).trigger('change');
  });
});
